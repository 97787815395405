import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

import home from '@/views/home'
import auth from '@/views/auth'
import index from '@/views/index'
import basregister from '@/views/basregister'
import basregisterSingle from '@/views/basregisterSingle'
import doAction from '@/views/doAction'
import payoutExport from '@/views/payoutExport'
import waitFor from '@/views/waitFor'
import sok from '@/views/sok'
import download from '@/views/download'

function requireSession(to, from, next) {
  if (!store.getters.isLoggedIn) {
    next({ name: 'auth', query: { redirect_to: to.fullPath } })
  }
  return next()
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/t/:trust',
      component: index,
      children: [
        {
          path: '',
          name: 'start',
          redirect: to => {
            return {
              name: 'basregister',
              params: {
                trust: to.params.trust,
                type: 'Employer'
              }
            }
          }
        },
        {
          path: 'do/:type/:primaryKey/:actiontype',
          name: 'do',
          component: doAction,
          props: { iscommon: false },
          beforeEnter: requireSession
        },
        {
          path: 'wait/:type/:primaryKey/for/:condition',
          name: 'wait-for',
          component: waitFor,
          props: { iscommon: false },
          beforeEnter: requireSession,
          meta: { noHistory: true }
        },
        {
          path: 'basregister/:type',
          name: 'basregister',
          component: basregister,
          props: { iscommon: false },
          beforeEnter: requireSession
        },
        {
          path: 'basregister/:type/:primaryKey',
          name: 'basregister-single',
          component: basregisterSingle,
          props: { iscommon: false },
          beforeEnter: requireSession
        },
        {
          path: 'payout-export/:primaryKey',
          name: 'payout-export',
          component: payoutExport,
          props: { iscommon: false },
          beforeEnter: requireSession
        },
        {
          path: 'gemensamt',
          component: index,
          children: [
            {
              path: '',
              name: 'common-start',
              redirect: to => {
                return {
                  name: 'common-basregister',
                  params: {
                    trust: to.params.trust,
                    type: 'Advisor'
                  }
                }
              }
            },
            {
              path: 'do/:type/:primaryKey/:actiontype',
              name: 'common-do',
              component: doAction,
              props: { iscommon: true },
              beforeEnter: requireSession
            },
            {
              path: 'wait/:type/:primaryKey/for/:condition',
              name: 'common-wait-for',
              component: waitFor,
              props: { iscommon: true },
              beforeEnter: requireSession,
              meta: { noHistory: true }
            },
            {
              path: 'basregister/:type',
              name: 'common-basregister',
              component: basregister,
              props: { iscommon: true },
              meta: { showCommonNotice: true },
              beforeEnter: requireSession
            },
            {
              path: 'basregister/:type/:primaryKey',
              name: 'common-basregister-single',
              component: basregisterSingle,
              props: { iscommon: true },
              meta: { showCommonNotice: true },
              beforeEnter: requireSession
            }
          ],
          beforeEnter: requireSession
        },
        {
          path: 'search',
          name: 'sok',
          component: sok,
          props: { iscommon: true },
          beforeEnter: requireSession
        }
      ],
      beforeEnter: requireSession
    },
    {
      path: '/',
      name: 'home',
      component: home,
      beforeEnter: requireSession,
      meta: { hideNavigation: true }
    },
    {
      path: '/auth',
      name: 'auth',
      component: auth,
      meta: { hideNavigation: true }
    },
    {
      path: '/download/:bucket/:path',
      name: 'download',
      component: download,
      beforeEnter: requireSession,
      meta: { hideNavigation: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!Vue.prototype.$routeHistory) {
    Vue.prototype.$routeHistory = []
  }

  // if path contains '/new', then don't add them to the history
  const noHistory = from.meta && from.meta.noHistory
  if (!from.fullPath.includes('/new') && from.fullPath !== '/' && !noHistory) {
    // Find current route, if it exists and remove it from the history
    const index = Vue.prototype.$routeHistory.findIndex(route => route.fullPath === to.fullPath)
    if (index > -1) {
      // If we find the route, remove it from the history
      Vue.prototype.$routeHistory.splice(index, 1)
    } else if (!from.query || !from.query.back) {
      // If we don't find the route and the back query parameter is not set,
      // add the route to the history
      Vue.prototype.$routeHistory.push(from)
    }
  }

  next()
})

export default router
